.drop-file-preview__item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: var(--input-bg);
  padding: 15px;
  border-radius: 20px;
}

.drop-file-preview__item img {
  width: 29px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.drop-file-preview__item__del {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}
